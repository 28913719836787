.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  width: 100%;
  height: 49.5px;
}

.header-item {
  flex: 1;

  &__right {
    text-align: right;
  }
}

.label-container {
  display: inline-block;
  text-align: center;
  height: 70px;
  width: 100%;
  margin-top: -1.5rem;
}

.label {
  text-transform: uppercase;

  &__status {
    text-align: center;
    width: 100%;
  }
}

.status {
  &__live {
    color: var(--wds-signal-success);
  }

  &__stopped {
    color: var(--wds-signal-information);
  }
}

.veo-logo {
  height: 100%;
}

.team-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-body {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
  row-gap: 0.25rem;
}
