.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
}

.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  max-width: 529px;
  width: 100%;
}

.streams-list-container,
.streams-list-container-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.streams-list-container-skeleton {
  row-gap: 1.5rem;
}

.live-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}

.live-text {
  text-transform: uppercase;
  color: var(--wds-signal-success);
}

.streams-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border: 1px solid pink;
}

.no-live-games-text {
  text-transform: uppercase;
}
