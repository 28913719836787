@use '../breakpoints' as *;

.container {
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 375px;
  row-gap: 1rem;
}

.split-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 1rem;
}

.split-container-item {
  flex: 1;
  overflow: hidden;
}

.split-input {
  width: 100%;
}

.stream-list-button {
  width: 100%;
}

.sub-header-skeleton-container {
  margin: 1rem 0;
}

.scoreboard-visibility-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  background-color: var(--wds-surface-container-low);
  padding: 1rem;
  box-sizing: border-box;
  row-gap: 1rem;
}

.scoreboard-visibility-text {
  text-transform: uppercase;
}

.promotional-container {
  width: 100%;
  padding: 1rem 2.5rem;
  background-color: var(--wds-surface-container-low);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
}

.promotional-container-body {
  row-gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promotional-container-text {
  text-transform: uppercase;
}

.banner-image {
  width: 100%;
}
