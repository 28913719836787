.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  row-gap: 5rem;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
  align-items: center;
}

.sub-header {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
  align-items: center;
}

.text {
  text-align: center;
  width: 100%;
}
