@keyframes pulse {
  0%,
  100% {
    opacity: 100%;
  }
  50% {
    opacity: 50%;
  }
}

.container {
  background-color: var(--wds-surface-container);
  border-radius: 0.5rem;
  animation: pulse 4s infinite;
}
