.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.menu-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  width: 100vw;
  height: calc(100dvh - 86px);
  box-sizing: border-box;
  background-color: var(--wds-surface-background);
  z-index: 100;

  visibility: hidden; // Initially hidden
  opacity: 0; // Initially transparent
  transition: visibility 0s 0.5s, opacity 0.5s linear; // Delay the visibility transition
}

.show {
  visibility: visible; // Visible when the 'show' class is added
  opacity: 1; // Opaque when the 'show' class is added
  transition: visibility 0s, opacity 0.5s linear; // No delay when showing the element
}
