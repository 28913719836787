.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--wds-surface-container-low);
  padding: 1rem;
  row-gap: 0.5rem;
  border-radius: 0.5rem;
}

.text {
  color: var(--wds-component-states-primary-default);

  &__score {
    font-size: 4rem !important;
  }
}
