.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  column-gap: 0.5rem;
  border-top: 1px solid var(--wds-surface-container-low, #141a14);
  width: 100%;

  &:last-child {
    border-bottom: 1px solid var(--wds-surface-container-low, #141a14);
  }
}

.skeleton-loader-container {
  width: 100%;
  height: 128px;
  background-color: var(--wds-surface-container);
  border-radius: 0.5rem;
}

.destination-text {
  text-transform: uppercase;
  text-align: center;
}

.stream-destination-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 79px;
  row-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.team-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  column-gap: 0.5rem;
  align-items: center;

  &__away {
    justify-content: flex-end;
  }
}

.team-name-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.crest-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
