.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: var(--wds-surface-background);
  color: var(--wds-on-surface-primary);
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto;
}

@font-face {
  font-family: Protokoll;
  src: url('./assets/fonts/ProtokollRegular-Web.woff') format('woff'),
    url('./assets/fonts/ProtokollRegular-Web.woff2') format('woff2');
  font-weight: 400; // Regular
  font-style: normal;
}

@font-face {
  font-family: Protokoll;
  src: url('./assets/fonts/ProtokollMedium-Web.woff') format('woff'),
    url('./assets/fonts/ProtokollMedium-Web.woff2') format('woff2');
  font-weight: 500; // Medium
  font-style: normal;
}

@font-face {
  font-family: Protokoll;
  src: url('./assets/fonts/ProtokollBold-Web.woff') format('woff'),
    url('./assets/fonts/ProtokollBold-Web.woff2') format('woff2');
  font-weight: 700; // Bold
  font-style: normal;
}
