.promotional-container {
  position: relative;
  width: 100%;
  padding: 1rem 2.5rem;
  background-color: var(--wds-surface-container-low);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  border-radius: 0.5rem;
}

.promotional-container-body {
  row-gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__off {
    & g[mask='url(#mask0_1968_1578)'] line,
    & g[mask='url(#mask0_1968_1545)'] line {
      stroke: var(--wds-surface-container-highest);
    }
  }
}

.banner-image {
  width: 100%;
}

.toggle-container {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}
